import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import "./index.css"
import axios from "axios"
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();
axios.defaults.withCredentials = true;
axios.defaults.baseURL = "https://admin.victoriainn-manado.com";
const container = document.getElementById("root");

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
